import React, { useEffect } from 'react'
import { StatusBar } from 'expo-status-bar';
import { Button, SafeAreaView, StyleSheet, Text, TouchableHighlight, View } from 'react-native';
import { ListFilterIcon, Star, ChevronRight, BoxIcon, CrownIcon, HomeIcon, UserIcon, UserCircleIcon, Lock, Axis3dIcon } from 'lucide-react-native';
import { Picker } from '@react-native-picker/picker';
import { useState } from 'react';
import axios from 'axios'

const HomeAdmin = ({ navigation, route }) => {

    const user = route.params.user;

    const goToEvent = () => {
        navigation.navigate('Event', { event: selectedEvent });
    }

    const goToAdmin = () => {
        navigation.navigate('Admin');
    }

    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        axios.post(`https://qa2.purpleprofile.pt/api/events/all`, { user: user })
            .then(data => {
                setEvents(data.data.data);
                setLoading(false);
                setSelectedEvent(data.data.data[0].id);
            })
    };

    console.log(events)

    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.header}>
                <TouchableHighlight activeOpacity={0.3} underlayColor={'#fff'} onPress={goToAdmin}>
                    <Lock color="black" />
                </TouchableHighlight>
                <Text style={styles.title}>Lights Purple Admin</Text>
                <Star color="black" />
            </View>

            <View style={styles.content}>
                {!loading &&
                    <View style={{ padding: 2, marginTop: 20 }}>
                        <Picker
                            style={{ height: 30, width: 200, borderRadius: 10, borderWidth: 1, borderColor: 'gray' }}
                            selectedValue={selectedEvent}
                            onValueChange={(value) => { setSelectedEvent(value) }}
                            itemStyle={styles.pickerItem}>
                            {events.map((event, key) =>
                                <Picker.Item key={key} label={event.name} value={event.id} />
                            )}
                        </Picker>
                    </View>
                }

                <View style={{ marginTop: 50 }}>
                    <Button onPress={goToEvent} title='Click me to teleport to the event!' />
                </View>
            </View>

            <View style={styles.footer}>
                <HomeIcon color='gray' />
                <BoxIcon color='gray' />
                <CrownIcon color='gray' />
                <UserCircleIcon color='gray' />
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingTop: 50,
        flex: 1,
        backgroundColor: '#fff',
        maxWidth: 1000,
        marginHorizontal: 'auto'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 10
    },
    title: {
        fontSize: 18
    },
    content: {
        flex: 1,
        padding: 10,
        alignItems: 'center'
    },
    pickEvent: {
        marginTop: 50,
        borderRadius: 50,
        borderColor: 'gray',
        borderWidth: 1,
        width: '60%',
        height: 40,
        paddingHorizontal: 10
    },
    footer: {
        height: 40,
        paddingHorizontal: 30,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        bottom: 0,
    }
});

export default HomeAdmin