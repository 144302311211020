import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useRef } from 'react';
import { Text, Animated, Easing, StyleSheet } from 'react-native';

const Gradient = () => {

  // useEffect(() => {
  //   const animateBackground = () => {
  //     Animated.loop(
  //       Animated.sequence([
  //         Animated.timing(backgroundAnimation, {
  //           toValue: 1,
  //           duration: 750,
  //           easing: Easing.linear,
  //           useNativeDriver: false,
  //         }),
  //         Animated.timing(backgroundAnimation, {
  //           toValue: 0,
  //           duration: 750,
  //           easing: Easing.linear,
  //           useNativeDriver: false,
  //         }),
  //       ]),
  //     ).start();
  //   };

  //   animateBackground();
  // }, [backgroundAnimation]);

  // const backgroundInterpolation = backgroundAnimation.interpolate({
  //   inputRange: [0, 1],
  //   outputRange: ['0% 50%', '100% 50%'],
  // });

  return (
    <Animated.View style={{ flex: 1, height: '100%', backgroundColor: 'white' }}>
      <LinearGradient
        style={styles.background}
        colors={['#ee7752', '#e73c7e', '#23a6d5', '#23d5a6']}
      >
      </LinearGradient>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: '#ee7752', // Fallback color if gradient is not supported
  },
});

export default Gradient