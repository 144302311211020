import React, { useState, useEffect, useRef } from 'react'
import { View } from 'react-native';

const Intermittent = ({ countdown }) => {

  const [isWhite, setWhite] = useState(true);
  const backgroundColor = isWhite ? '#ffffff' : '#000000';
  const backgroundColorRef = useRef(backgroundColor);
  let duration = 300;

  useEffect(() => {
    const color = setInterval(() => {
      setWhite((prevIsWhite) => !prevIsWhite);
      backgroundColorRef.current = isWhite ? '#000000' : '#ffffff';
      console.log(backgroundColorRef.current);
    }, duration);

    return () => clearInterval(color);
  }, []);

  return <View style={{ flex: 1, backgroundColor }} />;
}

export default Intermittent