import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import React from 'react'
import Home from '../pages/Home'
import Event from '../pages/Event'
import Admin from '../pages/Admin'
import HomeAdmin from '../pages/HomeAdmin'

const Stack = createNativeStackNavigator()

const Router = () => {
    return (
        <NavigationContainer>
            <Stack.Navigator screenOptions={{ headerShown: false }}>
                <Stack.Screen name="Home" component={Home} />
                <Stack.Screen name="Event" component={Event} />
                <Stack.Screen name="Admin" component={Admin} />
                <Stack.Screen name="HomeAdmin" component={HomeAdmin} />
            </Stack.Navigator>
        </NavigationContainer>
    )
}

export default Router