import React, { useState, useEffect, useRef } from 'react'
import { View, Animated, Text } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { socket } from '../helper/socket';

const SolidColor = ({ initialColor, countdown, upperText, lowerText }) => {

  const [previousColor, setPreviousColor] = useState();
  const [color, setColor] = useState('');

  const [upper, setUpper] = useState('');
  const [lower, setLower] = useState('');

  useEffect(() => {
    setColor(initialColor);
  }, [initialColor])

  useEffect(() => {
    setUpper(upperText);
    setLower(lowerText);
  }, [upperText, lowerText])


  return (
    <Animated.View
      style={{
        flex: 1,
        backgroundColor: color,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Text style={{ fontSize: 20, paddingVertical: 10 }}>{upper}</Text>
      {countdown &&
        <Text style={{ fontSize: 20, paddingVertical: 10 }}>{countdown}</Text>
      }
      <Text style={{ fontSize: 20, paddingVertical: 10 }}>{lower}</Text>
    </Animated.View>
  );
}

export default SolidColor