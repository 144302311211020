import axios from 'axios'
import { Navigation } from 'lucide-react-native';
import React, { useState } from 'react'
import { View, Text, StyleSheet, SafeAreaView, TextInput, TouchableHighlight } from 'react-native'
import { Toast } from 'react-native-toast-message/lib/src/Toast';

const Admin = ({ navigation }) => {

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const login = () => {
        axios.post(`${process.env.EXPO_PUBLIC_API_URL}/api/auth/login`, {
            username,
            password
        })
            .then(data => {
                if (data.status === 200) {
                    const { userInfo } = data.data;

                    navigation.navigate('HomeAdmin', { user: userInfo.id });

                    Toast.show({
                        type: 'success',
                        text1: 'Login successful'
                    })
                }
            })
    }

    return (
        <SafeAreaView style={styles.container}>
            <View style={{ display: 'flex', flex: '1', alignItems: 'center' }}>
                <Text style={styles.title}>Admin dashboard</Text>

                <View style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <View style={styles.inputView}>
                        <TextInput
                            style={styles.TextInput}
                            placeholder="Username."
                            placeholderTextColor="#003f5c"
                            onChangeText={(username) => setUsername(username)}
                        />
                    </View>
                    <View style={styles.inputView}>
                        <TextInput
                            style={styles.TextInput}
                            placeholder="Password"
                            placeholderTextColor="#003f5c"
                            secureTextEntry={true}
                            onChangeText={(password) => setPassword(password)}
                        />
                    </View>

                    <TouchableHighlight style={styles.button} onPress={login}>
                        <Text style={{ color: 'white' }}>Login</Text>
                    </TouchableHighlight>
                </View>
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingTop: 50,
        flex: 1,
        backgroundColor: '#fff',
        maxWidth: 800,
        width: '100%',
        margin: 'auto'
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    inputView: {
        backgroundColor: "#bababa",
        borderRadius: 30,
        width: "100%",
        height: 50,
        marginBottom: 20,
        alignItems: "center",
    },
    TextInput: {
        backgroundColor: "#bababa",
        borderColor: 'red',
        borderRadius: 30,
        height: 50,
        flex: 1,
        padding: 10,
        paddingHorizontal: 30,
    },
    button: {
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderRadius: 20,
        backgroundColor: '#171717',
        fontSize: 16
    }
})

export default Admin