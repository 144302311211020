import React, { useState, useEffect } from 'react'
import { View, StyleSheet, SafeAreaView, Vibration, TouchableOpacity, Modal, Platform, Text } from 'react-native'
import { Camera } from 'expo-camera'
import { socket } from '../helper/socket';
import SolidColor from '../effects/solidcolor';
import Gradient from '../effects/gradient';
import Welcome from '../effects/welcome';
import Intermittent from '../effects/intermittent';
import { CameraIcon, Heart, Smile, ChevronLeft, ChevronLeftIcon } from 'lucide-react-native'
import Cameraa from '../effects/camera';

// let Torch
// if (Platform.OS === 'android') {
//     Torch = require('react-native-torch').default;
// }

const Event = ({ route, navigation }) => {

    // define states for intermittent flash/continous flash and vibrate.
    // if socket call continous flash, check intermittent flash, same for vibrate

    const eventId = route.params.event;

    const [displayComponent, setDisplayComponent] = useState('');

    const [initialColor, setInitialColor] = useState('');
    const [time, setTime] = useState(false);
    const [countdown, setCountdown] = useState(null);
    const [upperText, setUpperText] = useState('');
    const [lowerText, setLowerText] = useState('');

    const [flashOn, setFlashOn] = useState(false);

    const [cameraOn, setCameraOn] = useState(false);
    const [permission, requestPermission] = Camera.useCameraPermissions();

    const [reactions, setReactions] = useState();

    const toggleFlashlight = () => {
        if (!flashOn) {
            Torch.switchState(true);
            setFlashOn(true)
        } else {
            Torch.switchState(false);
            setFlashOn(false)
        }
    }

    useEffect(() => {
        let countdownInterval;

        const startCountdown = () => {

            const currentTime = new Date().getTime();

            const timer = new Date(time).getTime();
            const remainingTime = Math.max(0, timer - currentTime);

            if (remainingTime > 0) {
                const hours = Math.floor(remainingTime / 3600000);
                const minutes = Math.floor((remainingTime % 3600000) / 60000);
                const seconds = Math.floor((remainingTime % 60000) / 1000);

                const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
                    .toString()
                    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

                setCountdown(formattedTime);
            } else {
                clearInterval(countdownInterval);
                setCountdown(null);
            }
        };

        const stopCountdown = () => {
            clearInterval(countdownInterval);
            setCountdown(null);
        };

        if (time) {
            startCountdown();
            countdownInterval = setInterval(startCountdown, 1000);
        }

        return () => {
            clearInterval(countdownInterval);
        };
    }, [time]);

    // every socket listener
    useEffect(() => {

        socket.emit('join', eventId);

        socket.emit('syncData', { event: eventId });

        socket.on('syncData', data => {
            const { currentDisplay, color, upperText, lowerText, currentTimer } = data

            if (currentDisplay) setDisplayComponent(currentDisplay);
            if (color) setInitialColor(color);
            if (currentTimer) setTime(currentTimer);
            if (upperText) setUpperText(upperText)
            if (lowerText) setLowerText(lowerText)

        })

        socket.on('getTimer', (timer) => {
            setTime(timer)
        });

        socket.on('stopTimer', () => {
            setTime(-1);
        })

        socket.on('startWelcome', () => {
            console.log('Hello')
            setDisplayComponent('welcome');
        })

        socket.on('intermittent', () => {
            setDisplayComponent('intermittent');

        })

        socket.on('colorChange', (newColor) => {
            if (displayComponent != 'solidcolor') {
                setDisplayComponent('solidcolor')
            }
            setInitialColor(newColor);
            let color = newColor;
        })

        socket.on('upperText', (text) => {
            setUpperText(text)
        })

        socket.on('lowerText', (text) => {
            setLowerText(text)
        })

        socket.on('gradient', () => {
            setDisplayComponent('gradient')
        })

        socket.on('intermittentVibrate', () => {
            console.log('hello')
            Vibration.vibrate(1000 * 1000);
        })

        socket.on('vibrate', (duration) => {
            Vibration.vibrate(duration * 1000);
        })

        socket.on('intermittentFlashlight', () => {

        })

        socket.on('flashlight', ({ duration }) => {
            Torch.switchState(true);
            setTimeout(() => {
                Torch.switchState(false);
            }, duration)
        })

        socket.on('stopAll', () => {
            Torch.switchState(false);
            Vibration.cancel();
        })

        socket.on('reactions', data => {
            if (data.state === 'off') {
                setReactions(false)
            } else {
                setReactions(true)
            }
        })

        // clean all listeners on component unmount
        return () => {
            socket.off('getTimer')
            socket.off('stopTimer')
            socket.off('startWelcome')
            socket.off('intermittent')
            socket.off('colorChange')
            socket.off('upperText')
            socket.off('lowerText')
            socket.off('gradient')
            socket.off('vibrate')
            socket.off('flashlight')
            socket.off('stopAll')
        }

    }, [socket])

    const handleCamera = async () => {
        if (Platform.OS === 'android') {
            console.log('web')
        }

        if (permission.status !== 'granted') {
            const { status } = await requestPermission();
            if (status !== 'granted') {
                // Handle camera permission denied
                return;
            }
        }

        setCameraOn(true);
    };

    const sendHeart = () => {
        socket.emit('heart', eventId)
    }

    const sendSmile = () => {
        socket.emit('smile', eventId)
    }

    if (displayComponent) {

        return (
            <SafeAreaView style={styles.container}>

                {/* {cameraOn ?
                    <View style={{ flex: 1, borderWidth: 2, borderColor: 'red' }}>
                        <Cameraa event={eventId} />
                    </View>
                    :
                    <> */}
                {displayComponent === 'welcome' && <Welcome countdown={countdown} />}
                {displayComponent === 'solidcolor' && <SolidColor upperText={upperText} lowerText={lowerText} countdown={countdown} initialColor={initialColor} />}
                {displayComponent === 'gradient' && <Gradient upperText={upperText} lowerText={lowerText} countdown={countdown} />}
                {displayComponent === 'intermittent' && <Intermittent upperText={upperText} lowerText={lowerText} countdown={countdown} />}

                <View style={{ position: 'absolute', top: 30, left: 20 }}>
                    <TouchableOpacity onPress={() => navigation.navigate('Home')}>
                        <ChevronLeftIcon color='gray' size={40} />
                    </TouchableOpacity>
                </View>

                {/* <View style={{ position: 'absolute', bottom: 30, left: 20, display: 'flex', flexDirection: 'row', gap: 10 }}>
                            <TouchableOpacity onPress={() => handleCamera()}>
                                <CameraIcon color='white' size={40} />
                            </TouchableOpacity>
                        </View> */}

                {reactions &&
                    <View style={{ position: 'absolute', bottom: 30, right: 20, display: 'flex', flexDirection: 'row', gap: 10 }}>
                        <TouchableOpacity onPress={sendHeart}>
                            <Heart color='white' size={40} />
                        </TouchableOpacity>

                        <TouchableOpacity onPress={sendSmile}>
                            <Smile color='white' size={40} />
                        </TouchableOpacity>
                    </View>
                }
                {/* </> */}
                {/* } */}
            </SafeAreaView>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
})

export default Event