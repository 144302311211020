import React, { useEffect } from 'react'
import { StatusBar } from 'expo-status-bar';
import { Button, SafeAreaView, StyleSheet, Text, TouchableHighlight, View } from 'react-native';
import { ListFilterIcon, Star, ChevronRight, BoxIcon, CrownIcon, HomeIcon, UserIcon, UserCircleIcon, Lock } from 'lucide-react-native';
import { Picker } from '@react-native-picker/picker';
import { useState } from 'react';

const Home = ({ navigation }) => {

    const goToEvent = () => {
        navigation.navigate('Event', { event: selectedEvent });
    }

    const goToAdmin = () => {
        navigation.navigate('Admin');
    }

    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        try {
            const response = await fetch(`https://qa2.purpleprofile.pt/api/events`); // Replace with your API URL
            const data = await response.json();
            setEvents(data.data);
            setLoading(false)
            setSelectedEvent(data.data[0].id)
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    };

    return (
        <SafeAreaView style={styles.container}>
            
            <View style={styles.header}>
                {/* <TouchableHighlight activeOpacity={0.3} underlayColor={'#fff'} onPress={goToAdmin}>
                    <Lock color="black" />
                </TouchableHighlight> */}
                <Text style={styles.title}>Get Ready</Text>
                {/* <Star color="black" /> */}
            </View>

            <View style={styles.content}>
                {!loading &&
                    <Text style={{ fontSize: 20 }}>{events[0].name}</Text>
                    // <View style={{ padding: 2, marginTop: 20 }}>
                    //     <Picker
                    //         style={{ height: 30, width: 200, borderRadius: 10, borderWidth: 1, borderColor: 'gray' }}
                    //         selectedValue={selectedEvent}
                    //         onValueChange={(value) => { setSelectedEvent(value) }}
                    //         itemStyle={styles.pickerItem}>
                    //         {events.map((event, key) =>
                    //             <Picker.Item key={key} label={event.name} value={event.id} />
                    //         )}
                    //     </Picker>
                    // </View>
                }

                <View style={{ marginTop: 50, paddingHorizontal: 50 }}>
                    <TouchableHighlight underlayColor='#d9d9d9' onPress={goToEvent} style={styles.button}>
                        <Text style={{textAlign: 'center'}}>Clica para te teleportares para o evento!</Text>
                    </TouchableHighlight>
                </View>
            </View>

        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingTop: 30,
        flex: 1,
        backgroundColor: '#fff',
        maxWidth: 1000,
        marginHorizontal: 'auto'
    },
    header: {
        display: 'flex',

        flexDirection: 'row',
        justifyContent: 'center',
        paddingHorizontal: 20
    },
    title: {
        fontSize: 18
    },
    content: {
        flex: 1,
        padding: 10,
        marginTop: 40,
        alignItems: 'center'
    },
    button: {
        backgroundColor: '#bababa',
        borderRadius: 10,
        padding: 10
    },
    pickEvent: {
        marginTop: 50,
        borderRadius: 50,
        borderColor: 'gray',
        borderWidth: 1,
        width: '60%',
        height: 40,
        paddingHorizontal: 10
    },
    footer: {
        height: 40,
        paddingHorizontal: 20,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        bottom: 0,
    }
});

export default Home