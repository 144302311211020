import React, { useEffect, useState, useRef } from 'react'
import { Platform, Animated, Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { SvgUri } from 'react-native-svg'
import { Canvas, useFrame, useThree, Vector3 } from '@react-three/fiber'
import { socket } from '../helper/socket';
import Svg, { Defs, ClipPath, Path, G } from "react-native-svg"

const Scene = () => {
    return (
        <>
            <ambientLight />
            <mesh rotation={[0, 10, 0]}>
                <boxGeometry attach="geometry" args={[1, 1, 1]} />
                <meshStandardMaterial attach="material" color={"#6be092"} />
            </mesh>
        </>
    );
}

const Welcome = ({ countdown, upperText, lowerText }) => {
    const meshRef = useRef();

    const [upper, setUpper] = useState('');
    const [lower, setLower] = useState('');
  
    useEffect(() => {
      setUpper(upperText);
      setLower(lowerText);
    }, [upperText, lowerText])

    function RoughBall() {
        const meshRef = useRef();

        useFrame((_, delta) => {
            meshRef.current.rotation.y += 1 * delta
        });

        return (
            <mesh ref={meshRef}>
                <icosahedronGeometry args={[10, 4]} />
                <meshLambertMaterial color={0xff00ee} wireframe />
            </mesh>
        );
    }

    const scaleAnimation = useRef(new Animated.Value(1)).current;

    useEffect(() => {
        const animateScale = () => {
            Animated.sequence([
                Animated.timing(scaleAnimation, {
                    toValue: 1.05,
                    duration: 1500,
                    useNativeDriver: true,
                }),
                Animated.timing(scaleAnimation, {
                    toValue: 1,
                    duration: 1500,
                    useNativeDriver: true,
                }),
            ]).start(() => {
                // Repeat the animation after it completes
                setTimeout(animateScale, 50);
            });
        };

        animateScale();
    }, [scaleAnimation]);

    return (
        <View style={styles.container}>
            {/* <View style={styles.image}>
                <Canvas camera={{ fov: 45, position: [0, 0, 40] }}>
                    <ambientLight intensity={0.5} />
                    <directionalLight position={[0, 10, 5]} intensity={1} />
                    <RoughBall />
                </Canvas>
            </View> */}

            <Animated.View style={{ scale: scaleAnimation, width: 200, height: 200, display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                <Svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Group 238"
                    width={133.995}
                    height={164.922}
                    viewBox="0 0 133.995 164.922"
                >
                    <Defs>
                        <ClipPath id="a">
                            <Path
                                data-name="Rectangle 6"
                                d="M0 0h133.995v164.922H0z"
                                fill="none"
                            />
                        </ClipPath>
                    </Defs>
                    <G data-name="Group 92" clipPath="url(#a)">
                        <Path
                            data-name="Path 72"
                            d="M52.472 99.797l.51-4.746a57.887 57.887 0 00-.3-10.7l-.041-.412.434-.716c5.457-8.974 6.415-10.741 6.572-11.083l2.219 1.038c-.39.842-2.645 4.654-6.705 11.332.284 2.905.377 5.254.39 7.011a26.72 26.72 0 015.57-4.982c.724-2.473 3.652-10.585 4.052-11.318l2.145 1.184c-.412.835-3.758 10.161-3.939 11.144l-.1.563-.5.3c-3.877 2.223-7.536 7.426-7.571 7.478z"
                            fill="#1a1818"
                        />
                        <Path
                            data-name="Path 73"
                            d="M55.71 82.615a1.1 1.1 0 01-.512-.124 1.14 1.14 0 01-.495-1.532l4.582-7.622a6.317 6.317 0 00.652-2.862 53.647 53.647 0 011.442-8.462c.514-2.453 1.122-5.353 1.8-8.879v-.016l.02-.22.7-3.643.011-.047c.427-1.986 7.9-34.814 36.823-49.031a2 2 0 012.6.967c.994 1.886 3.312 8.046 3.407 24.483a2.14 2.14 0 01-.889 1.757l-7.021 5.028 5.077.291a2.114 2.114 0 011.845 2.878 51.383 51.383 0 01-2.8 5.665 84.45 84.45 0 01-29.693 30.695 37.587 37.587 0 00-5.161 3.72l-1.282 1.124a1.124 1.124 0 01-1.6-.112 1.143 1.143 0 01.112-1.606l1.281-1.124a39.526 39.526 0 015.468-3.949 82.185 82.185 0 0028.9-29.864 51.49 51.49 0 002.574-5.161l-6.656-.383a1.612 1.612 0 01-1.473-1.184 1.629 1.629 0 01.621-1.791l8.49-6.081c-.149-15.349-2.167-21.228-3.121-23.151-27.869 13.911-34.97 45.784-35.3 47.313a427.222 427.222 0 01-2.529 12.791 55.439 55.439 0 00-1.385 8.017 8.546 8.546 0 01-.9 3.88l-4.579 7.612a1.122 1.122 0 01-1.007.623"
                            fill="#1a1818"
                        />
                        <Path
                            data-name="Path 74"
                            d="M93.284.149a.641.641 0 00-.3.075c-28.7 15.353-34.554 49.463-34.559 49.49-1.776 12.185-3.291 18.3-3.224 21.139.006.3-.045.59-.068.886a45.159 45.159 0 012.286 4.276c1.7-2.863 2.127-3.658 2.227-3.876l.081.038a7.13 7.13 0 00.214-1.7 53.724 53.724 0 011.433-8.464 500.228 500.228 0 001.8-8.878v-.016l.02-.22.7-3.643.011-.047c.4-1.852 6.936-30.561 31.306-45.944a15.094 15.094 0 00-1.117-2.6 1 1 0 00-.81-.516m2.6 5.383C72.591 20.584 66.423 48.269 66.119 49.691a426.725 426.725 0 01-2.53 12.791 55.525 55.525 0 00-1.39 8.018 9.209 9.209 0 01-.4 2.649l.06.028q-.068.148-.214.419a7.5 7.5 0 01-.331.747l2.715-1.649a83.484 83.484 0 0031.028-33.03 55.566 55.566 0 002.33-5.05l-.49-.028a1.612 1.612 0 01-1.472-1.184 1.682 1.682 0 01-.052-.675l-5.562-.128a.5.5 0 01-.291-.9l8.28-6.371a1.01 1.01 0 00.384-.835c-.373-9.386-1.348-15.3-2.3-18.962"
                            fill="#d68834"
                        />
                        <Path
                            data-name="Path 75"
                            d="M59.644 72.14c-.1.218-.525 1.013-2.227 3.876l.112.245 1.757-2.923a5.437 5.437 0 00.439-1.159zm2.159 1.01c-.047.151-.1.3-.154.446.1-.181.168-.32.214-.419z"
                            fill="#100000"
                        />
                        <Path
                            data-name="Path 76"
                            d="M95.214 3.264C70.845 18.647 64.307 47.356 63.909 49.209l-.011.046-.7 3.644-.02.22v.016a479.382 479.382 0 01-1.8 8.878 53.665 53.665 0 00-1.441 8.459 7.162 7.162 0 01-.213 1.7 5.474 5.474 0 01-.439 1.159l-1.757 2.929q.361.791.686 1.6l.908-1.985a.989.989 0 01.382-.429l1.811-1.1a7.6 7.6 0 00.332-.748q.083-.22.154-.446a9.184 9.184 0 00.4-2.649 55.575 55.575 0 011.39-8.018c.7-3.305 1.558-7.42 2.529-12.791.3-1.423 6.471-29.107 29.768-44.16a32.09 32.09 0 00-.672-2.268m.159 29.463a1.682 1.682 0 00.052.675 1.613 1.613 0 001.473 1.184l.49.028q.112-.288.2-.538a.979.979 0 00-.892-1.319z"
                            fill="#100000"
                        />
                        <Path
                            data-name="Path 77"
                            d="M54.903 83.799a1.126 1.126 0 01-.536-.151 1.143 1.143 0 01-.417-1.555l25.3-44.4a1.128 1.128 0 011.546-.421 1.144 1.144 0 01.417 1.556l-25.3 44.4a1.132 1.132 0 01-1.009.572"
                            fill="#1a1818"
                        />
                        <Path
                            data-name="Path 78"
                            d="M28.442 160.75a14.459 14.459 0 00-4.161 1.063 3.8 3.8 0 00-2 1.5c-.066.169-.017.253.016.27a.891.891 0 00.666.186 5.812 5.812 0 002.552-.743 10.938 10.938 0 002.926-2.28m1.965-9.122a41.133 41.133 0 01-5.769 2.331l-.146.136v.016a.257.257 0 00.2.355c.26.033.537.067.812.117 2.1.389 3.722 1.217 4.437 2.281.488.76.8 1.993-.488 3.649l-.049.085-.162.186c-2.128 2.5-4.663 3.615-6.241 3.615h-.058a1.422 1.422 0 01-1.121-.405.9.9 0 01-.1-.913 4.371 4.371 0 012.307-1.841 15.964 15.964 0 014.989-1.183c.813-1.064.944-2.01.407-2.838-.8-1.2-2.99-1.977-5.136-2.179a.317.317 0 00-.309.2 122.298 122.298 0 01-2.812 5.744.289.289 0 01-.26.169l-.162-.05a.333.333 0 01-.1-.423c.456-.879 1.511-2.956 2.551-5.22a.33.33 0 00-.293-.473 7.774 7.774 0 00-2.339.406.3.3 0 01-.39-.169.322.322 0 01.179-.405c.861-.355 1.95-.726 3.1-1.115.163-.051.325-.118.487-.168l.2-.187c1.235-2.888 1.333-3.7 1.3-3.918a.303.303 0 11.6-.085c.017.186.1.709-.91 3.192a.328.328 0 00.406.439 36.625 36.625 0 004.583-1.909 9.272 9.272 0 002.275-1.605 2.11 2.11 0 00.7-1.689 1.256 1.256 0 00-.666-.946 7.146 7.146 0 00-4-.675 19.475 19.475 0 00-5.542 1.182c-3.69 1.4-6.6 4.055-7.265 6.638a4.369 4.369 0 00.943 3.986.345.345 0 01-.017.439.288.288 0 01-.438-.033 5.013 5.013 0 01-1.047-4.556 8.514 8.514 0 012.747-4.138 15.7 15.7 0 014.876-2.922 19.5 19.5 0 015.72-1.216 7.947 7.947 0 014.356.76 1.932 1.932 0 01.943 1.42c.146 1.385-.927 2.668-3.3 3.919"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 79"
                            d="M33.715 158.571a.775.775 0 00.407-.2c.113-.117.129-.2.015-.2a.674.674 0 00-.389.2c-.115.1-.13.2-.033.2m5.64.692a9.3 9.3 0 01-3.3 1.267 12.323 12.323 0 01-2.422.288 1.277 1.277 0 01-1.17-.49 1.444 1.444 0 01.082-1.351l.146-.271.032-.05c.521-.744 1.48-1.3 1.935-1.149a.426.426 0 01.292.506 1.4 1.4 0 01-.6.9 1.941 1.941 0 01-1.04.287.314.314 0 00-.293.22l-.049.152a.628.628 0 000 .422c.081.135.326.2.666.2a11.247 11.247 0 005.4-1.469.284.284 0 01.423.118.333.333 0 01-.1.422"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 80"
                            d="M38.541 160.885l-.179-.068a.348.348 0 01-.081-.405 10.707 10.707 0 00.91-2.145.3.3 0 01.375-.2.306.306 0 01.21.388c-.048.169-.113.321-.162.49l.1.068a7.559 7.559 0 001.122-1.47c.064-.118.195-.338.422-.287.244.05.277.27.293.439a1.264 1.264 0 00.487.98c.31.2 1.35.625 4.274.017a.308.308 0 01.358.236.322.322 0 01-.244.372c-2.276.473-3.9.439-4.714-.1a1.618 1.618 0 01-.439-.439.3.3 0 00-.5-.034 17.3 17.3 0 01-2.032 2.095z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 81"
                            d="M53.278 158.891a.331.331 0 01-.195.405 34.791 34.791 0 01-5.135 1.454l-.211.134-.146.2a.173.173 0 00.1.254.939.939 0 01.537.389 1.179 1.179 0 01-.342 1.368 2.3 2.3 0 01-.894.54 2.415 2.415 0 01-.52.068 2.652 2.652 0 01-.748-.119.3.3 0 01-.194-.388.289.289 0 01.373-.2 1.949 1.949 0 00.927.033 2.061 2.061 0 00.65-.372c.227-.253.276-.523.227-.591-.081-.135-.374-.186-.763-.119a.306.306 0 01-.309-.135v-.338c.065-.1.146-.254.26-.439a.162.162 0 00-.1-.253 1.126 1.126 0 01-.78-.456c-.163-.27-.276-.744.163-1.537.617-1.1 1.771-1.571 2.357-1.25.276.152.487.524.21 1.132a.306.306 0 01-.406.152.329.329 0 01-.146-.422c.065-.135.114-.287.049-.321-.211-.119-1.057.152-1.544 1.013-.211.406-.277.726-.179.879.114.186.471.254.959.2h.13c.211-.033.439-.068.682-.118a36.6 36.6 0 004.6-1.368.313.313 0 01.39.2"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 82"
                            d="M53.896 158.482h-.017a.585.585 0 00-.423.338l-.048.253.162.135.228.05.163-.1a2.242 2.242 0 00.1-.287.6.6 0 00-.016-.3zm-.911 1.351c-.114.22-.21.423-.276.575l.147.117a2.3 2.3 0 00.536-.489l-.049-.254-.065-.033a.224.224 0 00-.293.084m-.422 1.436a.456.456 0 01-.439-.236c-.342-.558.487-1.774.666-2.01a1.216 1.216 0 011.073-1.166.779.779 0 01.683.372 1 1 0 01.1.777.227.227 0 00.227.27 19.141 19.141 0 004.1-.591.306.306 0 01.389.237.328.328 0 01-.227.372 20.189 20.189 0 01-4.762.625l-.178.085a2.859 2.859 0 01-1.447 1.25z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 83"
                            d="M77.361 160.817a.242.242 0 00-.147-.422 13.9 13.9 0 00-4.436.912 13.273 13.273 0 00-1.593.658l-.163.136a4.363 4.363 0 01-.244.49.278.278 0 01-.26.169l-.146-.035-.065-.05a.309.309 0 00-.374-.033 3.046 3.046 0 00-1.219 1.2c-.1.254-.033.321-.016.338.211.186 1.072.22 2.8-.338a19.708 19.708 0 004.062-1.791 21.576 21.576 0 001.8-1.233m6.533-9.291a13.809 13.809 0 01-2.714 6.233 19.038 19.038 0 01-5.314 4.849 21.994 21.994 0 01-4.194 1.841 8.357 8.357 0 01-2.388.473 1.394 1.394 0 01-.975-.27.859.859 0 01-.163-1.048 5.078 5.078 0 012.39-2.027l.146-.136c1.608-3.327 4.485-10.355 5.055-12.162a.287.287 0 01.374-.186.306.306 0 01.194.389c-.536 1.74-2.86 7.365-4.47 10.895a.332.332 0 00.407.456 2.851 2.851 0 01.34-.118c1.788-.642 4.194-1.182 5.721-.844l.211-.051a14.813 14.813 0 004.778-8.379c.276-2.111-.227-3.75-1.446-4.78-1.772-1.469-4.893-1.571-8.37-.253a18.024 18.024 0 00-6.484 4.256c-1.479 1.571-2.308 3.209-2.161 4.274.113.795.763 1.216 1.933 1.284a.316.316 0 01.293.338.3.3 0 01-.308.287c-1.967-.1-2.422-1.183-2.52-1.824a4.13 4.13 0 01.5-2.281 10.62 10.62 0 011.837-2.517 18.238 18.238 0 016.7-4.392c3.673-1.4 7.02-1.284 8.955.338 1.4 1.166 1.966 3.023 1.674 5.355"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 84"
                            d="M90.244 158.267a.154.154 0 00-.293.017c-.081.22-.146.439-.211.659-.553 1.858-.683 3.192-.374 3.767a.517.517 0 00.423.287c.7.05 1.983-.524 4.21-3.632a53.705 53.705 0 003.721-6.115c1.674-3.192 2.275-5.524 1.674-6.385-.292-.405-.861-.506-1.738-.321-2 .422-4.242 2.551-5.591 5.287v.017c-.064.152.114.27.212.135a7.173 7.173 0 011.365-1.571.307.307 0 01.439.085.37.37 0 01-.081.439 11.409 11.409 0 00-2.585 3.733c-.211.422-.471 1-.732 1.638l-.032.1a2.9 2.9 0 00.391 2.044.321.321 0 01-.017.422.288.288 0 01-.439-.016 1.735 1.735 0 01-.341-.591m-.358 5.355h-.146a1.11 1.11 0 01-.91-.608q-.585-1.14.341-4.257a26.587 26.587 0 01.878-2.517l.033-.084a5.086 5.086 0 01.13-.845 17.063 17.063 0 011.316-3.75 12.6 12.6 0 012.779-3.8 7.232 7.232 0 013.234-1.841c1.122-.237 1.9-.034 2.325.574a3.459 3.459 0 01.146 2.821 21.2 21.2 0 01-1.771 4.24 55.2 55.2 0 01-3.755 6.2 18 18 0 01-2.373 2.754 3.576 3.576 0 01-2.227 1.115"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 85"
                            d="M102.009 160.788a.846.846 0 01-.732-.321 1.536 1.536 0 01-.13-1.183.158.158 0 00-.244-.168c-.194.135-.438.3-.552.371a4.463 4.463 0 01-2.162.947.6.6 0 01-.536-.287c-.227-.405.049-1.031.406-1.74a2.425 2.425 0 00.146-.321.31.31 0 01.391-.22.339.339 0 01.195.405c-.033.085-.1.2-.195.422a5.38 5.38 0 00-.326.709l-.016.05a.225.225 0 00.261.3l.243-.084a10.907 10.907 0 001.837-1.1c.65-.439.876-.591 1.088-.574l.261.136c.113.169.048.355-.049.726-.1.321-.309 1-.146 1.216l.292.084a5.58 5.58 0 001.9-.658 17.5 17.5 0 012.08-.811.292.292 0 01.374.236.321.321 0 01-.227.372 15.3 15.3 0 00-1.983.778 6.1 6.1 0 01-2.13.709z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 86"
                            d="M105.436 160.885l-.179-.068a.348.348 0 01-.081-.405 10.709 10.709 0 00.91-2.145.3.3 0 01.374-.2.306.306 0 01.211.388c-.048.169-.114.321-.162.49l.1.068a7.5 7.5 0 001.122-1.47c.064-.118.195-.338.422-.287.244.05.277.27.293.439a1.264 1.264 0 00.487.98c.309.2 1.35.625 4.274.017a.308.308 0 01.358.236.322.322 0 01-.244.372c-2.276.473-3.9.439-4.714-.1a1.618 1.618 0 01-.439-.439.3.3 0 00-.5-.034 17.3 17.3 0 01-2.032 2.095z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 87"
                            d="M114.178 158.482h-.017a.583.583 0 00-.422.338l-.049.253.162.135.228.05.163-.1a2.137 2.137 0 00.1-.287.6.6 0 00-.016-.3zm-.911 1.351c-.114.22-.21.423-.276.575l.147.117a2.3 2.3 0 00.536-.489l-.049-.254-.065-.033a.225.225 0 00-.293.084m-.422 1.436a.457.457 0 01-.439-.236c-.342-.558.487-1.774.666-2.01a1.216 1.216 0 011.073-1.166.777.777 0 01.683.372 1 1 0 01.1.777.227.227 0 00.227.27 19.141 19.141 0 004.1-.591.306.306 0 01.389.237.328.328 0 01-.227.372 20.189 20.189 0 01-4.762.625l-.178.085a2.856 2.856 0 01-1.447 1.25z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 88"
                            d="M1.464 116.837a2.482 2.482 0 01-1.064-.833 2.206 2.206 0 01-.4-1.286h1.722a1.121 1.121 0 00.351.786 1.23 1.23 0 00.867.289 1.329 1.329 0 00.885-.272.887.887 0 00.321-.712.825.825 0 00-.218-.59 1.534 1.534 0 00-.546-.365 8.866 8.866 0 00-.9-.295 9.6 9.6 0 01-1.27-.457 2.225 2.225 0 01-.838-.682 1.956 1.956 0 01-.35-1.22 2.193 2.193 0 01.356-1.25 2.277 2.277 0 011-.816 3.62 3.62 0 011.47-.283 3.177 3.177 0 012.016.607 2.257 2.257 0 01.856 1.7h-1.77a.928.928 0 00-.351-.689 1.316 1.316 0 00-.867-.272 1.108 1.108 0 00-.753.243.883.883 0 00-.281.705.73.73 0 00.213.538 1.621 1.621 0 00.529.347 9.249 9.249 0 00.89.306 9.454 9.454 0 011.275.463 2.29 2.29 0 01.85.694 1.941 1.941 0 01.356 1.215 2.3 2.3 0 01-.333 1.2 2.359 2.359 0 01-.977.884 3.3 3.3 0 01-1.528.331 3.788 3.788 0 01-1.511-.289"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 89"
                            d="M17.147 114.211l-1.159-3.378-1.161 3.378zm.437 1.3h-3.192l-.529 1.539h-1.689l2.884-8.086h1.872l2.884 8.086h-1.7z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 90"
                            d="M33.218 117.045H31.61l-3.642-5.541v5.541h-1.607v-8.086h1.607l3.642 5.552v-5.552h1.607z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 91"
                            d="M45.728 108.971v1.307h-2.137v6.767h-1.608v-6.767h-2.136v-1.307z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 92"
                            d="M56.846 114.211l-1.159-3.378-1.161 3.378zm.437 1.3H54.09l-.529 1.539h-1.689l2.884-8.086h1.872l2.884 8.086h-1.7z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 93"
                            d="M80.267 108.971v8.074h-1.609v-5.252l-2.148 5.252h-1.217l-2.16-5.252v5.252h-1.607v-8.074h1.825l2.551 6 2.55-6z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 94"
                            d="M91.8 114.211l-1.159-3.378-1.161 3.378zm.437 1.3h-3.193l-.529 1.539h-1.689l2.884-8.086h1.87l2.884 8.086h-1.7z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 95"
                            d="M102.62 112.684h1.344a1.337 1.337 0 00.977-.318 1.19 1.19 0 00.321-.885 1.136 1.136 0 00-.321-.862 1.37 1.37 0 00-.977-.306h-1.344zm2.528 4.361l-1.77-3.147h-.758v3.147h-1.611v-8.074h3.01a3.482 3.482 0 011.584.33 2.3 2.3 0 01.983.891 2.445 2.445 0 01.327 1.255 2.41 2.41 0 01-.46 1.44 2.354 2.354 0 01-1.366.884l1.918 3.274z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 96"
                            d="M119.299 108.971v1.307h-2.137v6.767h-1.609v-6.767h-2.137v-1.307z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 97"
                            d="M130.416 114.211l-1.159-3.378-1.161 3.378zm.438 1.3h-3.194l-.528 1.539h-1.689l2.884-8.086h1.872l2.883 8.086h-1.7z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 98"
                            d="M4.627 131.857a2.532 2.532 0 00.656-1.865 2.593 2.593 0 00-.656-1.882 2.476 2.476 0 00-1.863-.678H1.679v5.086h1.085a2.51 2.51 0 001.863-.662m.314-5.251a3.39 3.39 0 011.414 1.351 4.055 4.055 0 01.5 2.037 3.989 3.989 0 01-.5 2.024 3.4 3.4 0 01-1.414 1.341 4.54 4.54 0 01-2.12.474H.141v-7.7h2.677a4.549 4.549 0 012.12.475"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 99"
                            d="M14.751 127.378v1.931h2.572v1.225h-2.572v2.042h2.9v1.258h-4.434v-7.713h4.438v1.258z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 100"
                            d="M33.17 129.336a1.08 1.08 0 00.307-.822q0-1.126-1.249-1.126h-1.219v2.24h1.216a1.322 1.322 0 00.942-.292m1.594.337a2.145 2.145 0 01-.908.872 3.3 3.3 0 01-1.562.331h-1.282v2.957h-1.531v-7.7h2.816a3.377 3.377 0 011.512.309 2.149 2.149 0 01.938.849 2.513 2.513 0 01.021 2.383"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 101"
                            d="M42.889 127.378v1.931h2.575v1.225h-2.575v2.042h2.9v1.258h-4.435v-7.713h4.438v1.258z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 102"
                            d="M58.941 133.833h-1.535l-3.472-5.285v5.285h-1.535v-7.713h1.535l3.472 5.3v-5.3h1.535z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 103"
                            d="M69.941 131.13l-1.106-3.222-1.107 3.222zm.417 1.235h-3.046l-.5 1.468h-1.61l2.75-7.713h1.785l2.75 7.713h-1.625z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 104"
                            d="M84.054 128.449a1.806 1.806 0 00-.722-.739 2.191 2.191 0 00-1.074-.254 2.341 2.341 0 00-1.206.309 2.133 2.133 0 00-.822.883 3.129 3.129 0 00.006 2.671 2.134 2.134 0 00.839.882 2.446 2.446 0 001.249.309 2.157 2.157 0 001.435-.469 2.245 2.245 0 00.733-1.307h-2.629v-1.181h4.141v1.346a3.622 3.622 0 01-.657 1.489 3.717 3.717 0 01-1.3 1.1 3.8 3.8 0 01-1.78.414 4.009 4.009 0 01-2-.5 3.636 3.636 0 01-1.4-1.4 4.309 4.309 0 010-4.066 3.629 3.629 0 011.4-1.4 4.241 4.241 0 014.234.132 3.227 3.227 0 011.314 1.782z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 105"
                            d="M93.87 126.131v4.767a1.638 1.638 0 00.406 1.2 1.791 1.791 0 002.29 0 1.638 1.638 0 00.406-1.2v-4.767h1.545v4.759a3.084 3.084 0 01-.423 1.661 2.675 2.675 0 01-1.129 1.021 3.561 3.561 0 01-1.571.341 3.482 3.482 0 01-1.552-.341 2.6 2.6 0 01-1.1-1.021 3.192 3.192 0 01-.4-1.661v-4.755z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Rectangle 5"
                            d="M105.212 126.131h1.535v7.702h-1.535z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 106"
                            d="M117.746 131.13l-1.106-3.222-1.107 3.222zm-2.624-6.681a1.221 1.221 0 01.9-.359 1.053 1.053 0 01.4.073 2.888 2.888 0 01.4.2 1.09 1.09 0 00.547.21c.271 0 .427-.159.471-.475h.822a1.856 1.856 0 01-.455 1.087 1.221 1.221 0 01-.9.359 1.05 1.05 0 01-.4-.073 2.781 2.781 0 01-.395-.2 1.02 1.02 0 00-.548-.22q-.405 0-.482.486h-.811a1.854 1.854 0 01.455-1.087m3.04 7.917h-3.046l-.5 1.468h-1.61l2.75-7.713h1.785l2.75 7.713h-1.621z"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 107"
                            d="M131.3 132.217a2.144 2.144 0 00.822-.9 2.953 2.953 0 00.3-1.357 2.919 2.919 0 00-.3-1.351 2.118 2.118 0 00-.822-.889 2.35 2.35 0 00-1.216-.309 2.38 2.38 0 00-1.221.309 2.108 2.108 0 00-.827.889 2.928 2.928 0 00-.3 1.351 2.962 2.962 0 00.3 1.357 2.134 2.134 0 00.827.9 2.356 2.356 0 001.221.314 2.326 2.326 0 001.216-.314m-3.188 1.186a3.76 3.76 0 01-1.423-1.407 4.176 4.176 0 010-4.06 3.771 3.771 0 011.423-1.407 4.1 4.1 0 013.95 0 3.757 3.757 0 011.416 1.406 4.226 4.226 0 010 4.06 3.731 3.731 0 01-1.42 1.407 4.086 4.086 0 01-3.944 0"
                            fill="#211e1f"
                        />
                        <Path
                            data-name="Path 108"
                            d="M84.495 146.199a4.1 4.1 0 011.31 2.378l.355-.1a4.166 4.166 0 00-1.333-2.408z"
                            fill="#211e1f"
                        />
                    </G>
                </Svg>
            </Animated.View>

            <View style={{ color: '#ffffff', height: 50, width: 50 }}>
                <Text>{countdown}</Text>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        flex: 1,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    imageWrapper: {
        width: 250,
        height: 200
    },
    image: {
        width: 250,
        height: 200,
        zIndex: 20
    }
})

export default Welcome